<template>
  <b-container fluid>
    <b-row>
        <b-col lg="12" sm="12">
            <iq-card>
                <template v-slot:body>
                    <b-overlay :show="loading">
                        <b-row>
                            <b-col lg="12" sm="12">
                                <h4>{{ $t('budget.forwardComments') }}</h4>
                                <b-table-simple striped bordered small class="mt-2">
                                    <b-tr>
                                        <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                        <b-th>{{ $t('budget.sendFrom') }}</b-th>
                                        <b-th>{{ $t('budget.sendTo') }}</b-th>
                                        <b-th>{{ $t('budget.comment') }}</b-th>
                                    </b-tr>
                                    <b-tr v-for="(sinComment,index) in forward_comments" :key="index">
                                        <b-td>{{ $n(index+1) }}</b-td>
                                        <b-td>{{ getUserName(sinComment.sender_id) }}</b-td>
                                        <b-td>{{ getUserName(sinComment.receiver_id) }}</b-td>
                                        <b-td>{{ sinComment.comment }}</b-td>
                                    </b-tr>
                                </b-table-simple>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </template>
            </iq-card>
        </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { authServiceBaseUrl, incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { applicationForwardedComments } from '../../api/routes'
export default {
    props: ['item'],
    data () {
        return {
            loading: false,
            application: {},
            search: {},
            users: [],
            forward_comments: []
        }
    },
    created () {
        this.application = this.item
        this.getAllComments(this.application.id)
    },
    computed: {
    },
    methods: {
        async getForwardedUsers (forwards) {
            this.loading = true
            var userIds = []
            forwards.map((item, key) => {
                userIds.push(item.sender_id)
                userIds.push(item.receiver_id)
            })

            const params = Object.assign({}, this.search, { user_ids: userIds })
            await RestApi.getData(authServiceBaseUrl, '/user/user-list', params).then(response => {
                if (response.success) {
                    this.users = response.data
                } else {
                    this.users = []
                }
                this.loading = false
            })
        },
        getStatus (status) {
          if (status === 1) {
              return this.$t('budget.pending')
          } else if (status === 2) {
              return this.$t('budget.forward')
          } else if (status === 3) {
              return this.$t('budget.approve')
          } else if (status === 4) {
              return this.$t('budget.reject')
          }
        },
        getApplicationType (applicationType) {
            if (applicationType === 1) {
                return this.$i18n.locale === 'en' ? 'Individual' : 'ব্যক্তিগত'
            } else if (applicationType === 2) {
                return this.$i18n.locale === 'en' ? 'Group' : 'গ্রুপ'
            }
        },
        getLandType (landType) {
            if (landType === 1) {
                return this.$i18n.locale === 'en' ? 'Haor' : 'হাওর'
            } else if (landType === 2) {
                return this.$i18n.locale === 'en' ? 'Non-haor' : 'সমতল'
            }
        },
        getUserName (userId) {
            const user = this.users.find(user => user.value === userId)
            return user !== undefined ? (this.$i18n.locale === 'bn' ? user.text_bn : user.text_en) : ''
        },
        async getAllComments (applicationId) {
           this.loading = true
           const params = Object.assign({}, { subsidy_app_id: applicationId })
            await RestApi.getData(incentiveGrantServiceBaseUrl, applicationForwardedComments, params).then(response => {
                if (response.success) {
                    this.forward_comments = response.data
                    this.getForwardedUsers(response.data)
                }
                // this.loading = false
            })
        }
    }
}
</script>
