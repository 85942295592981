<template>
  <b-container fluid>
    <b-row>
        <b-col lg="12" sm="12">
            <iq-card>
                <template v-slot:body>
                    <b-overlay :show="loading">
                        <b-row>
                            <b-col lg="12" sm="12">
                                <b-table striped small bordered hover :items="columns"  thead-class="hidden_header"></b-table>
                                {{ $t('globalTrans.attachment') }} : <a target="_blank" v-if="item.file" :href="incentiveGrantServiceBaseUrl + item.file"><i class="fa fa-cloud" aria-hidden="true"></i> {{ $t('globalTrans.attachment_download') }}</a>
                                <br/>
                            </b-col>
                            <b-col lg="12" sm="12" class="mt-2">
                                <h4>{{ $t('dae_subsidy.farmer_info') }}</h4>
                                <b-table-simple striped bordered small class="mt-2">
                                    <b-tr>
                                        <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                        <b-th>{{ $t('dae_subsidy.farmer_name') }}</b-th>
                                        <b-th>{{ $t('dae_subsidy.nid') }}</b-th>
                                        <b-th>{{ $t('dae_subsidy.mobile_no') }}</b-th>
                                        <b-th>{{ $t('dae_subsidy.instrument_name') }}</b-th>
                                        <b-th>{{ $t('dae_subsidy.brand_name') }}</b-th>
                                        <b-th>{{ $t('dae_subsidy.model') }}</b-th>
                                        <b-th>{{ $t('dae_subsidy.priority') }}</b-th>
                                        <b-th>{{ $t('globalTrans.union') }}</b-th>
                                        <!-- <b-th style="">{{ $t('globalTrans.action') }}</b-th> -->
                                    </b-tr>
                                    <b-tr v-for="(detail, index) in application_details" :key="index">
                                        <!-- {{ detail }} -->
                                        <b-td>{{ $n(index+1) }}</b-td>
                                        <b-td>{{ ($i18n.locale === 'bn') ? detail.farmer_name_bn : detail.farmer_name }}</b-td>
                                        <b-td>{{ $n(detail.nid_no, { useGrouping: false }) }}</b-td>
                                        <b-td>{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(detail.mobile_no, { useGrouping: false } ) }}</b-td>
                                        <b-td>{{ ($i18n.locale === 'bn') ? detail.instrument_name_bn : detail.instrument_name }}</b-td>
                                        <b-td>
                                            {{ getColumnName($store.state.incentiveGrant.commonObj.macBrandList,detail.brand_id)}}
                                        </b-td>
                                        <b-td>{{ detail.model }}</b-td>
                                        <b-td>{{ $n(detail.sorting) }}</b-td>
                                        <b-td>
                                            <slot v-if="detail.union_id">{{ getUnionName(detail.union_id) }}</slot>
                                        </b-td>
                                    </b-tr>
                                </b-table-simple>
                            </b-col>
                        </b-row>
                        <!-- ==================Approve Form Start===================== -->
                        <!-- ==================Approve Form End===================== -->
                        <!-- ============Modal Start=============== -->
                        <b-modal id="modal-approve-1" size="lg" :title="$t('budget.approve')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                        <!-- <ApplicationApprove :applicationId="applicationId" :approveIds="approveIds" :key="applicationId"/> -->
                        <ApplicationApprove :approveIds="approveIds" :key="applicationId"/>
                        </b-modal>
                        <!-- ============Modal End================= -->
                        <b-modal id="modal-5" size="lg" :title="$t('dae_subsidy.application_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                            <Details :item="item" :key="itemId"></Details>
                        </b-modal>
                        <b-modal id="modal-1" size="lg" :title="$t('budget.forward')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                            <Forward :id="itemId" :key="itemId"/>
                        </b-modal>
                        <b-modal id="modal-2" size="lg" :title="rejectApproveTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                            <RejectApprove :id="itemId" :status="status" :key="itemId"/>
                        </b-modal>
                        <b-modal id="modal-3" size="lg" :title="$t('budget.forwardComment')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                            <ForwardComment :item="item" :key="itemId"/>
                        </b-modal>
                    </b-overlay>
                </template>
            </iq-card>
        </b-col>
    </b-row>
  </b-container>
</template>
<script>
import Forward from './Forward'
import RejectApprove from './RejectApprove'
import ForwardComment from './ForwardComment'
import ApplicationApprove from './ApplicationApprove'
import RestApi, { authServiceBaseUrl, incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { applicationSingleUpazillaAppDetails, applicationApproveAll } from '../../api/routes'
export default {
    components: {
        ApplicationApprove,
        Forward,
        RejectApprove,
        ForwardComment
    },
    props: ['item'],
    data () {
        return {
            loading: false,
            sinItem: '',
            search: {},
            users: [],
            incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
            application_details: [],
            applicationId: 0,
            approveCheckIds: [],
            rejectCheckIds: [],
            approveIds: [],
            itemId: 0,
            status: 0,
            rejectApproveTitle: '',
            saveBtnName: this.$t('globalTrans.save'),
            form: {
                // subsidy_app_id: this.applicationId,
                // allApproveIds: this.approveIds,
                allApproveIds: [],
                allRejectIds: [],
                note: ''
            }
        }
    },
    created () {
        this.sinItem = this.item
        this.sinItem_details = this.item.details
        this.getApplicationDetailsList(this.sinItem.circular_id, this.sinItem.upazilla_id, this.sinItem.forward_id)
    },
    computed: {
        seasonList: function () {
            return [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Aus' : 'আউশ', text_en: 'Aus', text_bn: 'আউশ' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Amon' : 'আমন', text_en: 'Amon', text_bn: 'আমন' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Boro' : 'বোরো', text_en: 'Boro', text_bn: 'বোরো' }
            ]
        },
        columns () {
            if (this.$i18n.locale === 'bn') {
                return [
                    {
                        key: this.$t('dae_grant_allocation_distribution.fiscal_year'),
                        val: this.getFiscalYearName(this.sinItem.fiscal_year_id),
                        key1: this.$t('dae_config.season_name'),
                        val1: this.getSeasonName(this.sinItem.season_id)
                    },
                    {
                        key: this.$t('dae_subsidy.circular_name'),
                        val: this.sinItem.circular_name_bn,
                        key1: this.$t('globalTrans.division'),
                        val1: this.sinItem.division_name_bn
                    },
                    {
                        key: this.$t('globalTrans.district'),
                        val: this.sinItem.district_name_bn,
                        key1: this.$t('globalTrans.upazila'),
                        val1: this.sinItem.upazilla_name_bn
                    },
                    {
                        key: this.$t('dae_subsidy.total_application'),
                        val: this.$n(this.sinItem.total_application, { useGrouping: false })
                    }
                ]
            } else {
                return [
                    {
                        key: this.$t('dae_grant_allocation_distribution.fiscal_year'),
                        val: this.getFiscalYearName(this.sinItem.fiscal_year_id),
                        key1: this.$t('dae_config.season_name'),
                        val1: this.getSeasonName(this.sinItem.season_id)
                    },
                    {
                        key: this.$t('dae_subsidy.circular_name'),
                        val: this.sinItem.circular_name,
                        key1: this.$t('globalTrans.division'),
                        val1: this.sinItem.division_name
                    },
                    {
                        key: this.$t('globalTrans.district'),
                        val: this.sinItem.district_name,
                        key1: this.$t('globalTrans.upazila'),
                        val1: this.sinItem.upazilla_name
                    },
                    {
                        key: this.$t('dae_subsidy.total_application'),
                        val: this.$n(this.sinItem.total_application, { useGrouping: false })
                    }
                ]
            }
        }
    },
    methods: {
        getColumnName (list, groupId) {
            const obj = list.find(item => item.value === groupId)
            if (typeof obj !== 'undefined') {
                return obj.text_bn
            } else {
                return ''
            }
        },
        applicationApprove (item) {
        // this.applicationId = item.id
        this.approveIds = this.approveCheckIds
        },
        async saveData () {
        this.loading = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        this.form.allApproveIds = this.approveCheckIds.filter(function (n) {
                return n !== undefined && n !== null && n !== false && n !== true && n !== 0 && n !== ''
            })
        this.form.allRejectIds = this.rejectCheckIds.filter(function (n) {
                return n !== undefined && n !== null && n !== false && n !== true && n !== 0 && n !== ''
            })
        const loadingState = { loading: false, listReload: false }
        // const formData = Object.assign(this.form, { allApproveIds: this.approveCheckIds, status: 3 })
        const formData = Object.assign(this.form, { status: 3 })

        result = await RestApi.postData(incentiveGrantServiceBaseUrl, applicationApproveAll, formData)

        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)
        this.loading = false
        if (result.success) {
            this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
            this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
            })
            this.$bvModal.hide('modal-approve-1')
            this.$bvModal.hide('modal-5')
        } else {
            this.$refs.form.setErrors(result.errors)
        }
        },
        approveMessage () {
            if (this.form.note) {
                this.$swal({
                title: this.$t('globalTrans.approveMsg'),
                showCancelButton: true,
                confirmButtonText: this.$t('globalTrans.yes'),
                cancelButtonText: this.$t('globalTrans.no'),
                focusConfirm: false
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.saveData()
                  }
                })
            }
        },
        async getForwardedUsers (forwards) {
            this.loading = true
            var userIds = []
            forwards.map((item, key) => {
                userIds.push(item.sender_id)
                userIds.push(item.receiver_id)
            })

            const params = Object.assign({}, this.search, { user_ids: userIds })
            await RestApi.getData(authServiceBaseUrl, '/user/user-list', params).then(response => {
                if (response.success) {
                    this.users = response.data
                } else {
                    this.users = []
                }
                this.loading = false
            })
        },
        forwardComment (item) {
            this.item = item
        },
        forward (item) {
            this.itemId = item.id
        },
        reject (item) {
            this.rejectApproveTitle = this.$t('budget.reject')
            this.itemId = item.id
            this.status = 4 // 4 mean reject
        },
        details (item) {
        this.item = item
        },
        getStatus (status) {
          if (status === 1) {
              return this.$t('budget.pending')
          } else if (status === 2) {
              return this.$t('budget.forward')
          } else if (status === 3) {
              return this.$t('budget.approve')
          } else if (status === 4) {
              return this.$t('budget.reject')
          } else if (status === 5) {
              return this.$t('budget.reject')
          }
        },
        getFiscalYearName (fiscalYearId) {
            const fiscalyearData = this.$store.state.commonObj.fiscalYearList.find(item => item.value === fiscalYearId)
               if (this.$i18n.locale === 'bn') {
                    return fiscalyearData.text_bn
                } else {
                    return fiscalyearData.text_en
                }
        },
        getApplicationType (applicationType) {
            if (applicationType === 1) {
                return this.$i18n.locale === 'en' ? 'Individual' : 'ব্যক্তিগত'
            } else if (applicationType === 2) {
                return this.$i18n.locale === 'en' ? 'Group' : 'গ্রুপ'
            }
        },
        getLandType (landType) {
            if (landType === 1) {
                return this.$i18n.locale === 'en' ? 'Haor' : 'হাওর'
            } else if (landType === 2) {
                return this.$i18n.locale === 'en' ? 'Non-haor' : 'সমতল'
            }
        },
        async getApplicationDetailsList (circularId, upazillaId, forwardId) {
           this.loading = true
           const params = Object.assign({}, { circular_id: circularId, upazilla_id: upazillaId, forward_id: forwardId })
            await RestApi.getData(incentiveGrantServiceBaseUrl, applicationSingleUpazillaAppDetails, params).then(response => {
                if (response.success) {
                   this.application_details = this.getApplicationRelatinalData(response.data)
                }
                this.loading = false
            })
        },
        getApplicationRelatinalData (data) {
      const listData = data.map(item => {
        const instrument = this.$store.state.incentiveGrant.commonObj.instrumentList.find(instrument => instrument.value === item.instrument_id)
        const instrumentObj = { instrument_name: instrument !== undefined ? instrument.text_en : '', instrument_name_bn: instrument !== undefined ? instrument.text_bn : '' }
        this.approveCheckIds.push(item.id)
        return Object.assign({}, item, instrumentObj)
      })
      return listData
    },
    selectUnselectApplication (event, applicationId) {
            if (event.target.checked) {
           this.approveCheckIds.push(applicationId)
           this.rejectCheckIds = this.rejectCheckIds.filter(function (item) {
                return item !== applicationId
            })
           } else {
            this.approveCheckIds = this.approveCheckIds.filter(function (item) {
                return item !== applicationId
            })
            this.rejectCheckIds.push(applicationId)
            }
        },
        getUnionName (unionID) {
            const obj = this.$store.state.commonObj.unionList.find(el => el.value === parseInt(unionID))
            if (obj !== undefined) {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            }
        },
        getSeasonName (id) {
            const data = this.seasonList.find(el => el.value === id)
            if (typeof data !== 'undefined') {
                return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
            } else {
                return ''
            }
         }
    }
}
</script>
<style lang="scss">
    .hidden_header {
        display: none;
    }
</style>
